// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqDg1AN3Pq_kB5QrgDwXgL6UFnetAw3uw",
  authDomain: "pevv5-3164e.firebaseapp.com",
  projectId: "pevv5-3164e",
  storageBucket: "pevv5-3164e.appspot.com",
  messagingSenderId: "610112047944",
  appId: "1:610112047944:web:283b9ab1374dc1d831817d"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



